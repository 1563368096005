.App {
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.Header {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 5px #282c34 solid;
  max-width: 70%;
  margin: 0 auto;
}

.Header-Logo { 
  width: auto;
  height: 100px;
}

.Header-Text {
  padding-left: 50px;
  display: flex;
  text-align:center;
}

.Footer{
  color: black;
  display: flex;
  align-items: left;
  justify-content: center;
  border-bottom: 5px #282c34 solid;
  max-width: 70%;
  margin: 0 auto;
}

.Footer-text{
  padding-left: 50px;
  display: flex;
  text-align:center;
}

.card {
  width: 150px;
  height: auto;
  margin: 10px auto;
  border: 2px solid black;
  color: white;
  background-color: hsla(0, 0%, 0%, 0.486);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.card-link{
  text-decoration: none;
}

.cardStyle{
  display:flex;
  margin: 0 auto;
  padding-top: 20px;
  padding-left: 100px;
  width: 70%;
  gap: 50px 50px;
  flex-wrap: wrap;
  align-content: center;
}

.load-more-but {
  display: flex;
  margin: 0 auto;
  max-height: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
  background-color: hsla(0, 0%, 0%, 0.486);
  color: white;
}